/* Base16 Eighties Colorscheme by Chris Kempson (http://chriskempson.com) */

:root {
  --base00: #2d2d2d;
  --base01: #393939;
  --base02: #515151;
  --base03: #747369;
  --base04: #a09f93;
  --base05: #d3d0c8;
  --base06: #e8e6df;
  --base07: #f2f0ec;
  --base08: #f2777a;
  --base09: #f99157;
  --base0a: #ffcc66;
  --base0b: #99cc99;
  --base0c: #66cccc;
  --base0d: #6699cc;
  --base0e: #cc99cc;
  --base0f: #d27b53;
}

.base00 { color: var(--base00); }
.base01 { color: var(--base01); }
.base02 { color: var(--base02); }
.base03 { color: var(--base03); }
.base04 { color: var(--base04); }
.base05 { color: var(--base05); }
.base06 { color: var(--base06); }
.base07 { color: var(--base07); }
.base08 { color: var(--base08); }
.base09 { color: var(--base09); }
.base0a { color: var(--base0a); }
.base0b { color: var(--base0b); }
.base0c { color: var(--base0c); }
.base0d { color: var(--base0d); }
.base0e { color: var(--base0e); }
.base0f { color: var(--base0f); }

@custom-media --breakpoint-md (max-width: 52em);

/* General Page Layout */

body {
  margin: 0;
  background-color: var(--base00);
  color: var(--base07);
  line-height: 1.5;
  font-size: 100%;
  font-family: 'Source Code Pro', monospace;
}

.container {
  max-width: 52em;
  margin-left: auto;
  margin-right: auto;
}

div.right {
  float:right;
}

div.clearfix {
  overflow: auto;
}

@media (--breakpoint-md) {
  .container {
    width: 100%;
  }
}

article.single section,
.article-list article {
  background-color: var(--base07);
  color: var(--base00);
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (--breakpoint-md) {
  article.single section,
  .article-list article {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

header, footer {
  background-color: var(--base01);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@media (--breakpoint-md) {
  header {
    margin-top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

footer {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.9em;
  color: var(--base03);
}

@media (--breakpoint-md) {
  footer {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Typography */

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: .5em;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

h1 { font-size: 2rem }
h2 { font-size: 1.5rem }
h3 { font-size: 1.25rem }
h4 { font-size: 1rem }
h5 { font-size: .875rem }
h6 { font-size: .75rem }

pre, code {
  font-family: inherit;
  font-size: inherit;
}

/* Header Layout */

header a.path {
  color: var(--base0d);
}

header span.caret {
  color: var(--base07);
}

/* Footer Layout */

footer a {
  color: var(--base03);
  text-decoration: none;
}

/* 404 Page Layout */

.page-not-found h1 {
  text-align: center;
  font-size: 5em;
}

.page-not-found h2 {
  text-align: center;
  font-size: 3em;
  color: var(--base04);
  margin-bottom: 4rem;
}

@media (--breakpoint-md) {
  .page-not-found h1 {
    font-size: 3em;
  }

  .page-not-found h2 {
    font-size: 2em;
  }
}

/* Homepage Layout */

@media (--breakpoint-md) {
  .homepage {
    margin-bottom: 2rem;
  }
}

.homepage h1.site-title {
  text-align: center;
  font-size: 5em;
  color: var(--base0c);
}

@media (--breakpoint-md) {
  .homepage h1.site-title {
    font-size: 3em;
  }
}

.homepage h1.headline {
  font-size: 3em;
  color: var(--base0a);
}

@media (--breakpoint-md) {
  .homepage h1.headline {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.homepage .hero-logo img {
  width: 100%;
}

.homepage section.categories,
.homepage section.tags {
  padding-left: 2rem;
  padding-right: 2rem;
}

.homepage .category,
.homepage .category a,
.homepage .tag,
.homepage .tag a {
  color: var(--base0e);
}

.homepage .tag {
  margin-right: 2em;
}

/* Post List Layout */

.article-list h1.list-title {
  font-size: 3em;
  color: var(--base0a);
}

.article-list article {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
}

.article-list article h2.headline,
.article-list article h2.headline a {
  margin-top: 0;
  color: var(--base0d);
}

.article-list article .meta {
  margin-bottom: 1rem;
}

.article-list article .meta .key {
  color: var(--base03);
}

.article-list article .meta .val,
.article-list article .meta .val a {
  color: var(--base0e);
}

.article-list article section.summary a { color: var(--base0f); }


/* Single Post Layout */

article.single .meta {
  font-size: 0.9em;
  text-align: right;
}

article.single .meta .key {
  color: var(--base03);
}

article.single .meta .val, article.single .meta .val a {
  color: var(--base0e);
}

@media (--breakpoint-md) {
  article.single .meta {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

article.single h1.headline {
  margin-top: 0;
  font-size: 3em;
  color: var(--base0a);
}

@media (--breakpoint-md) {
  article.single h1.headline {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

article.single section.body {
  padding-top: 4rem;
  padding-bottom: 3rem;
}

@media (--breakpoint-md) {
  article.single section.body {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
}

/* Highlight Colors */

article.single section.body h1 { color: var(--base0d); }
article.single section.body h2 { color: var(--base0b); }
article.single section.body h3 { color: var(--base09); }
article.single section.body h4 { color: var(--base08); }
article.single section.body h5 { color: var(--base02); }
article.single section.body h6 { color: var(--base03); }

article.single section.body a { color: var(--base0f); }

/* Article Elements */

article.single * {
  max-width: 100%;
}

article.single pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow-x: scroll;
  border-radius: 3px;
  padding: 2rem;
}

article.single p code {
  padding: 0.2em 0.5em;
  border-radius: 3px;
  background: var(--base03);
  color: var(--base07);
}

article.single figure, article.single div.highlight {
  box-sizing: border-box;
  max-width: 52rem;
  width: 52rem;
  margin-left: -8rem;
  margin-right: -8rem;
  margin-bottom: 1rem;
  padding: 1em;
  background-color: var(--base01);
}

@media (--breakpoint-md) {
  article.single figure, article.single div.highlight {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    border-radius: 3px;
  }
}

article.single figure img {
  max-width: 100%;
  width: 100%;
  border-radius: 3px;
}

article.single figure figcaption {
  margin-top: 1rem;
}

article.single figure figcaption h4 {
  margin-top: 0;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  color: var(--base07);
}

article.single table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

article.single th,
article.single td {
  padding: .25rem 1rem;
  line-height: inherit;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--base04);
}

article.single tr:last-child td {
  border-bottom: 0;
}

article.single th {
  text-align: left;
  font-weight: bold;
  vertical-align: bottom;
}

article.single td { vertical-align: top }

article.single blockquote {
  margin-left: 2rem;
  margin-right: 3rem;
  padding-left: 1rem;
  border-left: 5px solid var(--base0c);
}

article.single hr {
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--base04);
}

/* Pygments template by Jan T. Sott (https://github.com/idleberg) */

pre { background: var(--base00); color: var(--base07) }

.highlight .hll { background-color: var(--base02) }
.highlight .c { color: var(--base03) } /* Comment */
.highlight .err { color: var(--base08) } /* Error */
.highlight .k { color: var(--base0e) } /* Keyword */
.highlight .l { color: var(--base09) } /* Literal */
.highlight .n { color: var(--base07) } /* Name */
.highlight .o { color: var(--base0c) } /* Operator */
.highlight .p { color: var(--base07) } /* Punctuation */
.highlight .cm { color: var(--base03) } /* Comment.Multiline */
.highlight .cp { color: var(--base03) } /* Comment.Preproc */
.highlight .c1 { color: var(--base03) } /* Comment.Single */
.highlight .cs { color: var(--base03) } /* Comment.Special */
.highlight .gd { color: var(--base08) } /* Generic.Deleted */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gh { color: var(--base07); font-weight: bold } /* Generic.Heading */
.highlight .gi { color: var(--base0b) } /* Generic.Inserted */
.highlight .gp { color: var(--base03); font-weight: bold } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: var(--base0c); font-weight: bold } /* Generic.Subheading */
.highlight .kc { color: var(--base0e) } /* Keyword.Constant */
.highlight .kd { color: var(--base0e) } /* Keyword.Declaration */
.highlight .kn { color: var(--base0c) } /* Keyword.Namespace */
.highlight .kp { color: var(--base0e) } /* Keyword.Pseudo */
.highlight .kr { color: var(--base0e) } /* Keyword.Reserved */
.highlight .kt { color: var(--base0a) } /* Keyword.Type */
.highlight .ld { color: var(--base0b) } /* Literal.Date */
.highlight .m { color: var(--base09) } /* Literal.Number */
.highlight .s { color: var(--base0b) } /* Literal.String */
.highlight .na { color: var(--base0d) } /* Name.Attribute */
.highlight .nb { color: var(--base07) } /* Name.Builtin */
.highlight .nc { color: var(--base0a) } /* Name.Class */
.highlight .no { color: var(--base08) } /* Name.Constant */
.highlight .nd { color: var(--base0c) } /* Name.Decorator */
.highlight .ni { color: var(--base07) } /* Name.Entity */
.highlight .ne { color: var(--base08) } /* Name.Exception */
.highlight .nf { color: var(--base0d) } /* Name.Function */
.highlight .nl { color: var(--base07) } /* Name.Label */
.highlight .nn { color: var(--base0a) } /* Name.Namespace */
.highlight .nx { color: var(--base0d) } /* Name.Other */
.highlight .py { color: var(--base07) } /* Name.Property */
.highlight .nt { color: var(--base0c) } /* Name.Tag */
.highlight .nv { color: var(--base08) } /* Name.Variable */
.highlight .ow { color: var(--base0c) } /* Operator.Word */
.highlight .w { color: var(--base07) } /* Text.Whitespace */
.highlight .mf { color: var(--base09) } /* Literal.Number.Float */
.highlight .mh { color: var(--base09) } /* Literal.Number.Hex */
.highlight .mi { color: var(--base09) } /* Literal.Number.Integer */
.highlight .mo { color: var(--base09) } /* Literal.Number.Oct */
.highlight .sb { color: var(--base0b) } /* Literal.String.Backtick */
.highlight .sc { color: var(--base07) } /* Literal.String.Char */
.highlight .sd { color: var(--base03) } /* Literal.String.Doc */
.highlight .s2 { color: var(--base0b) } /* Literal.String.Double */
.highlight .se { color: var(--base09) } /* Literal.String.Escape */
.highlight .sh { color: var(--base0b) } /* Literal.String.Heredoc */
.highlight .si { color: var(--base09) } /* Literal.String.Interpol */
.highlight .sx { color: var(--base0b) } /* Literal.String.Other */
.highlight .sr { color: var(--base0b) } /* Literal.String.Regex */
.highlight .s1 { color: var(--base0b) } /* Literal.String.Single */
.highlight .ss { color: var(--base0b) } /* Literal.String.Symbol */
.highlight .bp { color: var(--base07) } /* Name.Builtin.Pseudo */
.highlight .vc { color: var(--base08) } /* Name.Variable.Class */
.highlight .vg { color: var(--base08) } /* Name.Variable.Global */
.highlight .vi { color: var(--base08) } /* Name.Variable.Instance */
.highlight .il { color: var(--base09) } /* Literal.Number.Integer.Long */
